import {
  ErrorMappingInterface,
  ValidationFormErrorInterface
} from './interfaces'

const translations = {
  addressType: {
    avenue: 'Avenida',
    garden: 'Jardim',
    patio: 'Pátio',
    plaza: 'Praça',
    square: 'Quadra',
    street: 'Rua'
  },
  agency: {
    cnpj: 'CNPJ',
    edit: 'Editar dados da imobiliária',
    name: 'Nome',
    title: 'Imobiliária'
  },
  banners: {
    commercialType: {
      button: 'Ver todos os imóveis',
      title: 'Olá empreendedor!',
      titleComplement: 'Procura um local para sua empresa?'
    },
    helloStudent: {
      button: 'Ver todos os imóveis',
      title: 'Olá estudante!',
      titleComplement: 'Procura um apê bem localizado?'
    },
    rightPlace: {
      button: 'Quero cadastrar meu imóvel',
      title: 'O lugar certo para a valorização do seu imóvel.'
    },
    simpleRent: {
      button: 'Cadastre seu imóvel',
      title: 'Alugue seu imóvel de forma rápida e simples',
      subtitle: 'Alugar seu imóvel nunca foi tão fácil'
    },
    quickRent: {
      button: 'Faça uma proposta',
      title: 'Agilidade e facilidade',
      subtitle:
        'Sem burocracia na hora de alugar um imóvel próximo de faculdades e mercados'
    }
  },
  contracts: {
    add: 'Novo contrato',
    copasaCemig: 'Copasa/Cemig',
    create: 'Gerar contrato',
    deliveryOfKeys: 'Entrega das chaves e manual',
    digitalizedContract: 'Contrato digitalizado',
    edit: 'Editar Contrato',
    entryInspection: 'Inspeção',
    entryInspectionSignature: 'Aprovação da Inspeção',
    registrationData: 'Dados do contrato',
    rentPaymentDay: 'Dia do pagamento',
    status: {
      filling: 'Em Preenchimento',
      active: 'Ativo',
      in_legal: 'Jurídico',
      in_vacancy: 'Em Desocupação',
      terminated: 'Rescindido'
    } as Record<string, string>,
    tenantAssignedContract: 'Assinatura do locatário',
    tenantBirthplace: 'Local de nascimento',
    tenantCountryOfBirth: 'Nacionalidade',
    tenantDocuments: 'Documentos do locatário',
    tenantMaritalStatus: 'Estado civil',
    tenantOccupation: 'Ocupação',
    title: 'Contratos',
    open: 'Abrir contrato',
    ownerAssignedContract: 'Assinatura do locador'
  },
  dashboard: {
    activeProperties: 'Imóveis ativos',
    activeContracts: 'Contratos ativos',
    canceledContracts: 'Contratos cancelados',
    closedContracts: 'Contratos fechados',
    realStateCollected: 'Imóveis captados',
    lastCreatedProperties: 'Últimos imóveis cadastrados',
    newProperties: 'Imóveis captados',
    title: 'Dashboard'
  },
  employee: {
    add: 'Novo funcionário',
    edit: 'Editar funcionário'
  },
  errors: {
    currencyRegex: 'Formato inválido para um campo monetário - X,XX',
    errorBoundary: 'Ops... Aconteceu um erro... Tente novamente.',
    expiredToken: 'Token expirado',
    fileLimit: (size: string) => `O tamanho máximo do arquivo deve ser ${size}`,
    floatRegex: 'Formato inválido para um campo percentual - X,XX',
    userAlreadyExists: 'Já existe um usuário com este e-mail'
  },
  financial: {
    title: 'Financeiro'
  },
  form: {
    errors: {
      badInput: () => `Formato inválido`,
      patternMismatch: (error: ValidationFormErrorInterface) =>
        `${error.inputName} inválido`,
      tooLong: (error: ValidationFormErrorInterface) =>
        `Digite até ${error.maxLength} caracteres`,
      tooShort: (error: ValidationFormErrorInterface) =>
        `Digite pelo menos ${error.minLength} caracteres`,
      valueMissing: () => `Campo obrigatório`,
      rangeOverflow: (error: ValidationFormErrorInterface) =>
        `Deve ser menor ou igual que ${error.max}`,
      rangeUnderflow: (error: ValidationFormErrorInterface) =>
        `Deve ser maior ou igual que ${error.min}`,
      stepMismatch: (error: ValidationFormErrorInterface) =>
        `Menor valor possível é ${error.step}`,
      typeMismatch: (error: ValidationFormErrorInterface) =>
        `Digite um ${error.inputType}`
    } as ErrorMappingInterface,
    reset: 'Limpar',
    submit: 'Enviar'
  },
  general: {
    active: 'Ativo',
    add: 'Adicionar',
    addPhoto: 'Adicionar nova foto',
    addFiles: 'Adicionar novos arquivos',
    address: 'Endereço',
    all: 'Todos',
    back: 'Voltar',
    birthdate: 'Data de nascimento',
    cancel: 'Cancelar',
    category: 'Categoria',
    clear: 'Limpar',
    checkAll: 'Marcar todos',
    checkDetails: 'Ver detalhes',
    city: 'Cidade',
    close: 'Fechar',
    code: 'Código',
    contact: 'Contato',
    contracts: 'Contratos',
    createdAt: 'Criado em',
    data: 'Dados',
    dataSavedSuccess: 'Dados salvos com sucesso!',
    description: 'Descrição',
    details: 'Detalhes',
    documents: 'Documentos',
    edit: 'Editar',
    email: 'E-mail',
    phone: 'Telefone',
    endDate: 'Data de término',
    filter: 'Filtrar',
    initDate: 'Data de início',
    justification: 'Justificativa',
    lastUpdatedAt: 'Última atualização',
    leasedRealState: 'Imóveis locados',
    logo: 'SeuLugar Logo',
    logout: 'Sair',
    maxPrice: 'Preço máximo',
    month: 'Mês',
    name: 'Nome',
    next: 'Próximo',
    noResultsFound: 'Não encontramos resultados para a sua busca...',
    notFilled: 'Não informado',
    number: 'Número',
    observation: 'Observações',
    openNewTab: 'Abrir',
    ownProperties: 'Imóveis próprios',
    photos: 'Fotos',
    price: 'Preço',
    prices: 'Preços',
    proposals: 'Propostas',
    property: 'Imóvel',
    rejectReason: 'Motivo de recusa',
    removePhoto: 'Remover foto',
    removeFile: 'Remover arquivo',
    save: 'Salvar',
    search: 'Buscar',
    select: 'Selecionar',
    send: 'Enviar',
    state: 'Estado',
    status: 'Situação',
    type: 'Tipo',
    update: 'Atualizar',
    updatedAt: 'Atualizado em',
    uncheckAll: 'Desmarcar todos',
    deleteProperty: 'Excluir imóvel',
    infoPhoto: 'Clique em uma foto para usar como capa'
  },
  login: {
    copyright: (year: number) =>
      `© ${year} - seu.lugar - Todos os direitos reservados`,
    createUser: 'Criar conta Seu.Lugar',
    errors: {
      passwordMustContainNumber: `A senha deve conter pelo menos um número`,
      passwordMustContainUpperCase: `A senha deve conter pelo menos uma letra maiúscula`,
      passwordMustContainLowerCase: `A senha deve conter pelo menos uma letra minúscula`,
      passwordMustHaveLength: (length: number) =>
        `A senha deve conter pelo menos ${length} caracteres`,
      passwordMustMatch: 'As senhas não são iguais'
    },
    forgotPassword: 'Esqueci minha senha',
    password: 'Senha',
    newPassword: 'Nova Senha',
    confirmNewPassword: 'Confirmar Nova Senha',
    recoverPassword: 'Recuperar senha',
    signin: 'Entrar'
  },
  properties: {
    add: 'Novo imóvel',
    addNewProposal: 'Criar proposta',
    address: 'Endereço do imóvel',
    addressType: 'Tipo',
    additionalParkingSpace: 'Vaga adicional de garagem',
    alreadyHaveProposal: 'Já possui uma proposta ativa',
    administrationFee: 'Taxa de administração',
    approximateValue: 'Valor aproximado',
    area: 'Área',
    bailInsurance: 'Seguro fiança',
    bathrooms: 'Banheiros',
    categories: {
      commercial: 'Comercial',
      residential: 'Residencial'
    } as Record<string, string>,
    checkWebsite: 'Ver imóvel no site',
    condominium: 'Condomínio',
    description: 'Características do imóvel',
    edit: 'Editar imóvel',
    estimated: 'Estimados',
    footage: 'Metragem',
    fireInsurance: 'Seguro incêndio',
    includedInCondominium: 'Itens inclusos no condomínio',
    intermediationFee: 'Taxa de intermediação',
    iptu: 'IPTU',
    itemsIncludedInCondominium: {
      reserveFund: 'Fundo de reserva',
      water: 'Água'
    },
    invalidZipCode: 'Formato de CEP inválido',
    locationReference: 'Complemento',
    minimumPrice: 'Valor mínimo aceito',
    neighborhood: 'Bairro',
    noPhoto: 'Ainda não há fotos adicionadas',
    noFile: 'Ainda não há arquivos adicionados',
    parkingSpaces: 'Vagas de garagem',
    propertyData: 'Dados do imóvel',
    propertyDoesNotHaveContracts: 'O imóvel não possui contratos',
    propertyDoesNotHaveActiveContract: 'O imóvel não possui contrato ativo',
    propertyDoesNotHaveProposals: 'O imóvel não possui propostas',
    propertyNotRented: 'O imóvel ainda não foi locado',
    propertyNotOwned: 'O imóvel não possui dono',
    otherValues: 'Demais valores',
    rent: 'Aluguel',
    registerPayment: 'Registrar pagamento',
    rooms: 'Quartos',
    sendPhoto: 'Enviar fotos',
    status: {
      available: 'Disponível para locação',
      awaitingSignature: 'Aguardando assinaturas',
      inactive: 'Inativo',
      pendingPhoto: 'Aguardando fotos',
      proposalInAnalysis: 'Proposta em análise',
      rent: 'Imóvel locado'
    },
    targetPrice: 'Valor anunciado',
    title: 'Imóveis',
    total: 'Total',
    types: {
      apartment: 'Apartamento',
      house: 'Casa',
      kitnet: 'Kitnet',
      land: 'Terreno',
      room: 'Sala',
      shed: 'Galpão',
      store: 'Loja',
      studio: 'Studio'
    } as Record<string, string>,
    zipCode: 'CEP',
    propertyOwner: 'Proprietário do imóvel',
    indicateOrRegister: 'Deseja  indicar ou cadastrar um imóvel?',
    indicate: 'Indicar',
    register: 'Cadastrar',
    mensageNewPhoto: 'Para adicionar uma foto capa, clique em alguma image',
    mensagePhotoIsPrimary:
      'Para marcar uma nova foto como capa, delete a capa atual!',
    infoOwner: 'Informar proprietário',
    notInfoOwner: 'Não informar proprietário',
  },
  proposals: {
    add: 'Nova proposta',
    edit: 'Editar proposta',
    title: 'Propostas',
    proposalData: 'Dados da proposta',
    status: {
      accepted: 'Aceita',
      filling: 'Em Preenchimento',
      insuranceAnalysis: 'Em Análise - Seguro',
      valueAnalysis: 'Em Análise - Valor',
      rejected: 'Recusada'
    } as Record<string, string>
  },
  roles: {
    admin: 'Administrador',
    customer: 'Usuário',
    employee: 'Funcionário'
  } as Record<string, string>,
  settings: {
    title: 'Configurações'
  },
  site: {
    home: {
      commercialProperties: {
        title: 'Imóveis comerciais',
        description:
          'Você pode encontrar apartamentos, casas e pontos comerciais para aluguel.',
        link: 'Quero ver todos os pontos comerciais'
      },
      lastRegisteredProperties: {
        description:
          'Nossos imóveis são todos certificados por profissionais da área e cada um deles tem sua documentação válida para aluguel.',
        title: 'Últimos imóveis cadastrados'
      },
      seeAlso: {
        description:
          'Você pode encontrar apartamentos, casas e pontos comerciais para aluguel.',
        title: 'Veja também',
        link: 'Quero ver todos os imóveis'
      }
    },
    favorites: {
      favoriteList: 'Lista de imóveis favoritos',
      favoriteListDescription:
        'Estes são os imóveis que você mais gostou e salvou para analisar depois.',
      mainBannerTitle: 'Seus imóveis favoritos',
      mainBannerSubtitle: 'Veja seus "queridinhos" da plataforma'
    },
    filter: {
      title: 'Imobiliária',
      subTitle: 'descomplicada',
      search: 'Filtro'
    },
    logo: {
      text: 'Seu imóvel sem burocracia'
    }
  },
  users: {
    add: 'Novo usuário',
    cpfCnpj: 'CPF/CNPJ',
    documents: 'Documentos',
    edit: 'Editar usuário',
    errors: {
      cpfFormat: 'Somente números ou XXX.XXX.XXX-XX',
      validContact: 'Somente números ou (XX)YXXXX-XXXX',
      duplicateContact: 'Esse número de contato já foi adicionado anteriormente. Por favor, verifique sua lista de contatos.'
    },
    fullName: 'Nome/Razão social',
    new: 'Criar usuário',
    newest: 'Mais recentes',
    noPhones: 'Não há telefones cadastrados',
    ownedProperties: 'Imóveis próprios',
    permissions: 'Permissões',
    personalData: 'Dados pessoais',
    phones: 'Telefones',
    phone: 'Telefone',
    registrationData: 'Dados cadastrais',
    rentedProperties: 'Imóveis alugados',
    rg: 'RG',
    role: 'Papel',
    search: 'Busque por um usuário',
    status: {
      active: 'Ativo',
      inactive: 'Inativo'
    } as Record<string, string>,
    title: 'Usuários',
    types: {
      owner: 'Proprietário',
      tenant: 'Locatário'
    } as Record<string, string>,
    userNotOwnerProperty: 'O usuário não é dono de imóvel',
    userNotRentedProperty: 'O usuário não tem imóvel alugado',
    viewDetails: 'Ir para detalhes do usuário',
    otherInfo: 'Informações adicionais'
  },
  visits: {
    title: 'Visitas'
  }
}

export default translations
