import './App.scss'

import Router from 'router'
import Theme from './styles/theme-admin'
import AuthProvider from 'common/auth/auth-provider'
import AuthModule from 'common/auth'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

function App() {
  const auth = AuthModule.getAuthData()
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider auth={auth}>
        <Theme>
          <Router />
        </Theme>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
