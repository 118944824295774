const Constants = {
  API_URl_PREFIX: 'api/v1',
  AUTH_LOCAL_STORAGE: 'auth',
  DEFAULT_FALLBACK_IMAGE_PATH: '/assets/svg/photo-placeholder.svg',
  DEFAULT_FALLBACK_PDF_PATH: '/assets/png/PDF_Fallback.png',
  REDIRECT_TO_LOCAL_STORAGE: 'redirectTo',
  TOKEN_LOCAL_STORAGE: 'token',
  ROLES: {
    ADMIN: 'ADMIN',
    EMPLOYEE: 'EMPLOYEE',
    CUSTOMER: 'CUSTOMER',
    PARTNER_BROKER: 'PARTNER_BROKER'
  }
}

export default Constants
