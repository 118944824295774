import BackdropMUI from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const Backdrop = () => {
  return (
    <BackdropMUI
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        bgcolor: 'white'
      }}
      open={true}
    >
      <CircularProgress color="error" />
    </BackdropMUI>
  )
}

export default Backdrop
