import React, { ReactNode } from 'react'
import I18n from 'common/i18n'
import Box from '@mui/system/Box'
import { PropsInterface, StateInterface } from './interfaces'

export default class ErrorBoundary extends React.Component<
  PropsInterface,
  StateInterface
> {
  public state: StateInterface = {
    hasError: false
  }

  static getDerivedStateFromError(error: Error): StateInterface {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  public render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Box sx={{ m: '1rem' }}>
          <h1>{I18n.errors.errorBoundary}</h1>
          <div>
            <h1>{this.state.error?.message}</h1>
            <h4>{this.state.error?.stack}</h4>
          </div>
        </Box>
      )
    }

    return this.props.children
  }
}
