import config from 'common/config'
import Constants from 'common/constants'
import Http from 'common/http'
import { SigninRequestInterface, SigninResponseInterface } from './interfaces'

const basePath = `${config.API_URL}/${Constants.API_URl_PREFIX}/auth`

const AuthService = {
  login: (
    email: string,
    password: string
  ): Promise<SigninResponseInterface> => {
    return Http.post<
      Omit<SigninRequestInterface, 'code'>,
      SigninResponseInterface
    >(`${basePath}/signin`, { email, password }, { credentials: 'omit' })
  },
  forgetPassword: (email: string): Promise<void> => {
    return Http.post<Omit<SigninRequestInterface, 'password' | 'code'>, void>(
      `${basePath}/forget-password`,
      { email },
      { credentials: 'omit' }
    )
  },
  resetPassword: (
    email: string,
    code: string,
    password: string
  ): Promise<void> => {
    return Http.post<SigninRequestInterface, void>(
      `${basePath}/reset-password`,
      { email, code, password },
      { credentials: 'omit' }
    )
  }
}

export default AuthService
