import { Navigate } from 'react-router-dom'
import { RouteInterface } from './interfaces'

export default function AnonymousRoute({
  children,
  redirectTo,
  isAuthenticated
}: RouteInterface) {
  return isAuthenticated ? <Navigate to={redirectTo} /> : children
}
