import { createTheme, ThemeProvider, ThemeOptions } from '@mui/material/styles'
import type {} from '@mui/lab/themeAugmentation'
import { ThemeInterface } from './interfaces'

const colors = {
  darkBlue: '#1D3557',
  mainBlue: '#457B9D',
  lightBlue: '#778098',
}
const themeOptions = createTheme({
  palette: {
    primary: {
      main: colors.mainBlue,
      light: colors.lightBlue,
      dark: colors.darkBlue
    }
  }
} as ThemeOptions)

export default function Theme(props: ThemeInterface) {
  return <ThemeProvider theme={themeOptions}>{props.children}</ThemeProvider>
}