import Constants from 'common/constants'
// import AuthModule from 'common/auth'
//import { setRedirectRoute } from 'router/redirect'
//import i18n from 'common/i18n'

const getToken = (): string => {
  try {
    const token = localStorage.getItem(Constants.TOKEN_LOCAL_STORAGE) || ''
    return token
  } catch {
    return ''
  }
}
/*
const isTokenInvalid = (token: string) => {
  const tokenInfo = AuthModule.parseJwt(token)

  return AuthModule.isTokenValid(tokenInfo)
}
*/
async function fetchData<T>(
  basePath: string,
  options?: RequestInit
): Promise<T> {
  try {
    const token = getToken()
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        ...{ Authorization: token }
      },
      ...(options || {})
    }
    /*
    if (requestOptions.credentials !== 'omit' && !isTokenInvalid(token)) {
      setRedirectRoute()
      window.location.href = '/login'
      return Promise.reject(i18n.errors.expiredToken)
    }
*/
    const response = await fetch(basePath, requestOptions)
    let payload = {} as T
    try {
      payload = await response.json()
    } catch (err: any) {
      // handling empty responses
      if (err.message !== 'Unexpected end of JSON input') {
        return Promise.reject(err)
      }
    }

    if (response.ok) {
      return Promise.resolve(payload)
    } else {
      return Promise.reject(payload)
    }
  } catch (err) {
    return Promise.reject(err)
  }
}

function get<T>(basePath: string, options?: RequestInit): Promise<T> {
  return fetchData<T>(basePath, options)
}

function update<T, R>(
  basePath: string,
  method: string,
  body?: T,
  options?: RequestInit
): Promise<R> {
  const optionsParam = options || {}

  return fetchData<R>(basePath, {
    ...optionsParam,
    method: method,
    body:
      body instanceof FormData || body instanceof Blob
        ? body
        : JSON.stringify(body)
  })
}

function post<T, R>(
  basePath: string,
  body: T,
  options?: RequestInit
): Promise<R> {
  return update<T, R>(basePath, 'POST', body, options)
}

function put<T, R>(
  basePath: string,
  body: T,
  options?: RequestInit
): Promise<R> {
  return update<T, R>(basePath, 'PUT', body, options)
}

function remove<T, R>(
  basePath: string,
  body?: T,
  options?: RequestInit
): Promise<R> {
  return update<T, R>(basePath, 'DELETE', body, options)
}

const httpMethods = {
  get,
  post,
  put,
  remove
}

export default httpMethods
