import Constants from 'common/constants'

const LOGIN_ROUTE = '/login'

export function clearRedirectRoute() {
  sessionStorage.removeItem(Constants.REDIRECT_TO_LOCAL_STORAGE)
}

export function setRedirectRoute() {
  if (window.location.pathname !== LOGIN_ROUTE) {
    sessionStorage.setItem(
      Constants.REDIRECT_TO_LOCAL_STORAGE,
      window.location.pathname
    )
  }
}

export function getRedirectRoute(isAuthenticated: boolean) {
  const redirectRoute = sessionStorage.getItem(
    Constants.REDIRECT_TO_LOCAL_STORAGE
  )

  return redirectRoute && redirectRoute !== LOGIN_ROUTE
    ? redirectRoute
    : ( isAuthenticated ? '/admin': '/')
}
