import { useAuth } from 'common/auth/auth-provider'
import { Navigate } from 'react-router-dom'
import { RouteInterface } from './interfaces'

export default function BackofficeRoute({
  children
}: Omit<RouteInterface, 'isAuthenticated' | 'redirectTo'>) {
  const auth = useAuth()
  const isAuthenticated = auth.isAuthenticated()

  if (!isAuthenticated) {
    return <Navigate to={'/login'} />
  }


  return !auth.isEmployee ? <Navigate replace={true} to={'/'} /> : children
}
